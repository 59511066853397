#header {
  position: absolute;
  width: 100%;
  padding-top: 35px;

  .logo {
    padding-left: 15px;
  }

  nav {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 10;
    font-weight: 300;
    transition: 0.2s ease-in-out;
    position: relative;
    flex-wrap: nowrap;
    padding: 0 45px;

    .nav_list {
      list-style: none;
      display: flex;
      justify-content: flex-end;
      margin-left: 0;
      padding: 0 4vh;
      align-items: center;
      margin-bottom: 0;
    }

    .nav_link {
      cursor: pointer;
      color: #ffffff;
      text-decoration: none;
      margin-right: 50px;
      position: relative;
      padding: 16px 0;
      font-size: $fs-base;
      margin: 0 20px;
      line-height: $fs-base;
      font-weight: $semibold;
      display: flex;
      align-items: center;
      transition: all 0.5s ease-in-out;

      &.active-item {

        &:before {
          content: '';
          display: block;
          position: absolute;
          bottom: 3px;
          left: 0;
          right: 0;
          height: 6px;
          width: 6px;
          border-radius: 10px;
          margin: 0 auto;
          background-color: #ffffff;
          transition: all 0.5s ease-in-out;

        }
      }

    }
  }

  .nav_item {
    &:last-child {
      @include gradient-bgcolor;
      @include border-radius(30px)
    }
  }
}

nav .nav_list .nav_link:active::before {
  background-color: #00dcff;
}

nav .nav_list .nav_link:hover::before,
nav .nav_list .nav_link:focus::before {
  -webkit-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.sm-logo {
  display: none;
}

.nav-toggle {
  display: none;
}

#header .btn-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (max-width: 1440px) {
  #header {
    padding-top: 25px;
  }
}

@media only screen and (max-width: 1024px) {
  #header {
    position: fixed;
    z-index: 2;
    width: 100%;
    background: $clr-white;
    height: 100px;
    padding: 0;

    .sm-logo {
      display: flex;
      padding: 25px 0 25px 25px;

      img {
        margin: inherit;
      }
    }

    .nav-toggle {
      display: block;
      position: absolute;
      top: 35px;
      right: 20px;
      background: transparent;
      border: 0;
    }
  }

  .nav {

    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: -webkit-transform 250ms cubic-bezier(0.5, 0, 0.5, 1);
    transition: -webkit-transform 250ms cubic-bezier(0.5, 0, 0.5, 1);
    transition: transform 250ms cubic-bezier(0.5, 0, 0.5, 1);
    transition: transform 250ms cubic-bezier(0.5, 0, 0.5, 1), -webkit-transform 250ms cubic-bezier(0.5, 0, 0.5, 1);
    height: 100vh;
  }

  .nav .nav_list {
    list-style: none;
    display: flex;
    height: 100vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: flex-start !important;
    text-align: center;
    align-items: flex-start !important;
    margin: 0;
    padding-top: 45px !important;
    background: #111;
    width: 100%;
  }

  .nav .logo {
    display: none;
  }

  .nav-open .nav {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  .nav-open .nav-toggle {
    z-index: 999;
  }

  .nav-open .nav {
    top: 0;
    padding: 0 !important;
  }


  .hamburger {
    cursor: pointer;
    padding: 10px 35px 16px 0px;
  }

  .hamburger span,
  .hamburger span:before,
  .hamburger span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 3px;
    width: 35px;
    background: $clr-black;
    position: absolute;
    display: inline-block;
    content: '';
    left: 0;
  }

  .hamburger span:before {
    top: -10px;
  }

  .hamburger span:after {
    bottom: -10px;
  }

  .hamburger span,
  .hamburger span:before,
  .hamburger span:after {
    -webkit-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .nav-open .hamburger span {
    background-color: transparent;
  }

  .nav-open .hamburger span:before,
  .nav-open .hamburger span:after {
    top: 0;
  }

  .nav-open .hamburger span:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .nav-open .hamburger span:after {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
    top: 10px;
  }
}