.service-page {
    p {
        margin-top: 20px;
        width: 90%;
    }

    .commodity-content {
        .ripple-content {
            text-align: left;
        }
    }

    .ripple-content {
        text-align: center;
    }
}

.text-Gradient {
    @include text-Gradient(56deg, 13.32%, 50.06%);
    font-weight: $semibold;
}

.list-container {
    // display: grid;
    // grid-template-columns: repeat(2, 1fr);
    // gap: 20px;
    margin-top: 40px;

    .list-item {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        padding-bottom: 20px;

        .tick-image {
            @include box-size(60px, 60px);
            background: $role-fill-one;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;

        }

        p {
            width: 100%;
            margin: 0;
            // padding-top: 6px;
        }

        &:nth-child(2) {
            .tick-image {
                background: $role-fill-two;
            }
        }

        &:nth-child(3) {
            .tick-image {
                background: $role-fill-one;
            }
        }

        &:nth-child(4) {
            .tick-image {
                background: $role-fill-four;
            }
        }

        &:nth-child(5) {
            .tick-image {
                background: $role-fill-one;
            }
        }
    }
}

.commodity-content {
    .ripple-small {
        left: auto;
        right: -7px;
        top: 30%;
        bottom: auto;
    }

    .ripple-large {
        bottom: 30%;
        left: -30px;
        right: auto;
        top: auto;
    }
}

.currencyItems {
    .ripple-large {
        bottom: 120px;
        top: auto;
    }

    .ripple-small {
        bottom: 50%;
    }
}

//meadia query
@media only screen and (max-width: 992px) {
    .list-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);

        .list-item {
            .tick-image {
                img {
                    width: 25px;
                }
            }
        }
    }

    .service-page {
        .ripple-content {
            margin-top: 50px;

            .ripple-large {
                width: 50px;
                height: 50px;
                right: -23px
            }
        }
    }

    .service-page {
        .commodity-content {
            .ripple-content {
                text-align: center;
            }
        }
    }

}

@media only screen and (max-width: 912px) {
    .list-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }


}

@media only screen and (max-width: 768px) {
    .list-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .service-page {
        .commodity-content {
            .ripple-content {
                text-align: center;
            }
        }
    }
}

@media only screen and (max-width: 820px) {
    .list-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .list-item p {
            width: 100%;
        }
    }

    .service-page {
        p {
            width: 100%;
            margin-top: 15px;
        }

        .commodity-content {
            .ripple-content {
                text-align: center;
            }
        }
    }

}

@media only screen and (max-width: 576px) {
    .list-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}