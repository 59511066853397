// advisors page
.advisors-list {
  .filter {
    display: flex;
    justify-content: flex-end;

    .search-input {
      margin-right: 16px;
    }
  }
}

//view advisor
.advisor-view {
  .tips-details {
    margin-top: 25px;
    padding-top: 25px;

    .filter {
      display: flex;
      justify-content: space-between;

      .refresh {
        @include flex-center;
      }

      .action {
        @include flex-center;

        .view-btn {
          margin-left: 10px;
          padding-left: 10px;
        }
      }
    }
  }

  .rating-review-section {
    &.large-cap {
      padding-top: 0;
    }

    .card {
      padding: 40px;
      margin-top: 50px;
      @include justify-center;
      margin-bottom: 28px;

      .rating {
        padding: 0 50px 0 0;
        border-right: 1px solid $grayBg;

        .star-list {
          @include flex-center;
          padding-bottom: 7px;

          .count-star {
            display: flex;
            justify-content: space-between;
            min-width: 27px;
            margin-right: 10px;

            .star-image {
              width: 13px;

              img {
                width: 100%;
              }
            }
          }

          &.star-5 {
            .progress {
              .progress-bar {
                background: $five-star;
              }
            }
          }

          &.star-4 {
            .progress {
              .progress-bar {
                background: $four-star;
              }
            }
          }

          &.star-3 {
            .progress {
              .progress-bar {
                background: $three-star;
              }
            }
          }

          &.star-2 {
            .progress {
              .progress-bar {
                background: $two-star;
              }
            }
          }

          &.star-1 {
            .progress {
              .progress-bar {
                background: $one-star;
              }
            }
          }
        }

        .progress {
          width: 100%;
          height: 8px;
          background: $progress;

          .progress-bar {
            border-radius: 30px;
          }
        }

        .overall-rating {
          display: flex;
          justify-content: space-between;
          padding-top: 12px;
          margin-top: 12px;

          .rating-count {
            text-align: center;
          }

          .rating-percentage {
            text-align: center;
          }

          .overall-count {
            display: flex;
            align-items: baseline;
            justify-content: center;

            .star-image {
              width: 18px;
              margin-left: 8px;

              img {
                width: 100%;
              }
            }

            h1 {
              font-size: $fs-h1-large;
              font-weight: $bold;
            }

            span {
              font-weight: $bold;
              font-size: $fs-h5;
            }
          }

          p {
            font-size: $fs-micro;
            color: $light-black;
            text-align: left;
          }
        }
      }

      .review {
        padding: 0 0 0 50px;

        p {
          font-size: $fs-mini;
          font-weight: $medium;

          span {
            font-size: $fs-micro;
            font-weight: $regular;
          }
        }

        .review-input {
          .review-label {
            margin-bottom: 10px;
          }
        }

        .action {
          padding-top: 11px;
          margin-top: 11px;
          @include flex-between;

          .stars {
            display: flex;
            width: 115px;
            justify-content: space-between;

            .star-image {
              width: 16px;
              cursor: pointer;

              img {
                width: 100%;
              }
            }
          }
        }

        textarea {
          &.custom-input {
            margin-top: 10px;
          }
        }

        //custom modal
        .custom-modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          z-index: 1;

          .custom-modal {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #fff;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            max-width: 400px;
            width: 100%;

            .modal-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-bottom: 10px;
              margin-bottom: 10px;

              h1 {
                margin: 0;
                font-weight: 600;
              }
            }

            .modal-body {
              text-align: center;
            }
          }
        }

        .filter-btn {
          margin-top: 20px;
        }

      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .advisor-view {
    .rating-review-section {
      .row {
        display: block;

        .rating {
          padding: 0 0 50px 0;
          border-right: none;
          border-bottom: 1px solid $grayBg;
        }
      }

      .card {
        .review {
          padding: 50px 0 0 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 460px) {
  .advisors-list {
    .filter {
      display: block;

      .search-input {
        margin-right: 0;
        margin-bottom: 16px;
        width: 100%;
      }
    }

    .drop-down {
      width: 100%;
    }
  }

  .advisor-view {
    .tips-details {
      .filter {
        display: block;

        .refresh {
          padding-bottom: 10px;
          margin-bottom: 10px;
        }
      }
    }

    .rating-review-section {
      .card {
        padding: 25px;

        .review {

          .action {
            margin: 11px 0 0;
            padding: 11px 0 0;
            display: block;

            .button {
              margin-top: 11px;
              padding-top: 11px;
            }
          }
        }
      }
    }
  }
}