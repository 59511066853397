.tips {
  .filter-icon {
    display: none;

    .filter-toggle {
      button {
        border: 0;
        @include bg-Gradient(56deg, 15.32%, 83.06%);
        padding: 10px;
        border-bottom-right-radius: 12px;
        border-top-right-radius: 12px;

        &.filter-open {
          img {
            transform: rotate(-90deg);
          }
        }

        &.filter-close {
          img {
            transform: rotate(90deg);
          }
        }

        img {
          filter: brightness(100);
        }
      }
    }
  }

  .tips-details {
    margin-top: 0;
    padding-top: 0;

    .heading {
      padding-right: 25px;
      margin-top: 35px;
      padding-top: 34px;
    }

    h5 {
      &.title {
        font-size: $h5-big;
        font-weight: $medium;

        &.sm {
          font-size: $fs-normal;
        }
      }
    }

    .filter-card {
      background: $white;
      padding: 25px;
      border-radius: 12px;
      margin-top: 40px;
      margin-right: 25px;

      &.filter-card {
        .drop-down {
          margin-top: 20px;
          padding-top: 20px;
          width: auto;
        }
      }

      .options {
        margin-top: 25px;
        padding-top: 25px;
      }
    }

    .btn-action {
      text-align: center;
      margin-top: 25px;
      padding-right: 25px;
      padding-top: 25px;
    }
  }
}

@media only screen and (max-width: 1220px) {
  .tips {
    .tips-section {
      position: relative;
    }

    .filter-icon {
      position: absolute;
      top: 30px;
      display: block;

      .filter-open {
        display: block;
      }

      .filter-close {
        display: none;
      }
    }

    .tips-filter {
      position: absolute;
      left: -100%;
      transition: all 0.3s ease-in-out;
    }

    .open-filter {
      .filter-icon {
        .filter-open {
          display: none;
        }

        .filter-close {
          display: block;
        }
      }

      .tips-filter {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        background: $white;
        position: absolute;
        left: 0;
        transition: all 0.3s ease-in-out;
        top: 74px;
        padding: 50px;
        width: 50%;
        z-index: 10;

        .accordion-menu {
          padding-right: 0;

          .accordion-item {
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          }
        }

        .top-advisors {
          padding-right: 0;

          .ads-item {
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            max-width: none;
          }
        }

        .filter-card {
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          margin-right: 0;
        }

        .date-filter {
          border-radius: 12px;
          padding-right: 0;
        }
      }

    }

    .tip-card {
      width: 100%;
    }

  }
}

@media only screen and (max-width: 992px) {
  .tips {
    .tips-details {
      .filter {
        flex-direction: column;

        .refresh {
          margin-bottom: 10px;
          padding-bottom: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 880px) {
  .tips {
    .open-filter {
      .tips-filter {
        width: 60%;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .tips {
    .tips-group {
      .tips-item {
        max-width: none;
      }
    }

    .open-filter {
      .tips-filter {
        width: 70%;
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .tips {
    .open-filter {
      .tips-filter {
        width: 80%;
      }
    }
  }
}

@media only screen and (max-width: 580px) {
  .tips {
    .open-filter {
      .tips-filter {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 460px) {
  .tips {
    .open-filter {
      .tips-filter {
        padding: 40px 20px;
      }
    }

    .tips-details {
      .filter {
        .action {
          flex-direction: column-reverse;
          align-items: flex-start;

          .view-btn {
            padding-left: 0;
            margin-left: 0;
            padding-bottom: 10px;
            margin-bottom: 10px;
          }
        }
      }
    }

    // .tips-item {
    //   height: 240px;

    //   .tips-item-header {
    //     div {
    //       &:first-child {
    //         flex-direction: column;
    //       }

    //       .typ-stus {
    //         width: fit-content;
    //         padding: 12px 0;
    //       }
    //     }

    //     .cat-type {
    //       display: flex;
    //       flex-direction: row !important;

    //       .time {
    //         padding-top: 0 !important;
    //         padding-left: 15px;
    //       }
    //     }
    //   }
    // }
  }
}