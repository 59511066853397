@import './menu';

.header-section {
    transition: all 0.3s ease-in-out;

    &.is-sticky {
        background: #f9f9f9;
        height: 85px;
        position: sticky;
        top: 0;
        z-index: 9;
        transition: all 0.3s ease-in-out;
        box-shadow: 0px 5px 31px -3px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

        #header {
            padding-top: 25px;

            nav {
                .nav_link {
                    color: $sec-txt;

                    &::before {
                        background-color: $sec-txt;
                    }

                    img {
                        filter: invert(1);
                    }
                }

                .nav_item {
                    &:last-child {
                        .nav_link {
                            color: $clr-white;
                        }
                    }
                }
            }
        }

        .header-profile {
            .pro-img {
                border: 1px solid $input-border;
            }

            .user-name {
                color: $sec-txt;

                img.down-arrow {
                    filter: invert(0);
                }
            }
        }

        .notify-head {
            img {
                filter: invert(1);
            }
        }
    }
}

.header-profile {
    position: relative;
    @include justify-center;
    cursor: pointer;

    .pro-img {
        @include box-size(35px, 35px);
        @include border-radius(30px);
        background: $clr-white;
        @include justify-center;
        overflow: hidden;

        span {
            color: $sec-txt;
            font-weight: $semibold;
        }
    }

    .user-name {
        padding-left: 15px;
        color: $clr-white;
        font-weight: $medium;
        text-transform: capitalize;
        @include flex-center;

        img.down-arrow {
            margin-left: 5px;
            filter: invert(1);
        }
    }
}

.profile-menu-dropdown {
    position: absolute;
    top: 100%;
    right: 0px;
    background-color: $clr-white;
    @include border-radius(10px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-width: 150px;
    transition: all 0.3s ease-in-out;
    animation: slide-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

    ul {
        list-style: none;
        padding: 8px 0;
        margin: 0;

        li {
            padding: 5px 15px;

            a {
                color: $sec-txt;
                font-size: $fs-milli;

                span {
                    display: inline-block;
                    width: 20px;

                    img {
                        margin-right: 6px;
                    }
                }
            }
        }
    }

}

@-webkit-keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }
}

@keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }
}

//   Notification style
.notify-head {
    cursor: pointer;
    margin-right: 20px;

    .notify-count {
        position: absolute;
        top: 0;
        right: 0;
        width: 10px;
        height: 10px;
        background: red;
        border-radius: 30px;
    }
}

.mobile-notify {
    display: none;
}

.bell {
    animation: ring 4s .7s ease-in-out infinite;
    transform-origin: 50% 4px;
}

@keyframes ring {
    0% {
        transform: rotate(0);
    }

    1% {
        transform: rotate(30deg);
    }

    3% {
        transform: rotate(-28deg);
    }

    5% {
        transform: rotate(34deg);
    }

    7% {
        transform: rotate(-32deg);
    }

    9% {
        transform: rotate(30deg);
    }

    11% {
        transform: rotate(-28deg);
    }

    13% {
        transform: rotate(26deg);
    }

    15% {
        transform: rotate(-24deg);
    }

    17% {
        transform: rotate(22deg);
    }

    19% {
        transform: rotate(-20deg);
    }

    21% {
        transform: rotate(18deg);
    }

    23% {
        transform: rotate(-16deg);
    }

    25% {
        transform: rotate(14deg);
    }

    27% {
        transform: rotate(-12deg);
    }

    29% {
        transform: rotate(10deg);
    }

    31% {
        transform: rotate(-8deg);
    }

    33% {
        transform: rotate(6deg);
    }

    35% {
        transform: rotate(-4deg);
    }

    37% {
        transform: rotate(2deg);
    }

    39% {
        transform: rotate(-1deg);
    }

    41% {
        transform: rotate(1deg);
    }

    43% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(0);
    }
}

.home-txt {
    display: none;
}

.nav_link {
    &.active-item {
        .home-outline-icon {
            display: none;
        }

        .home-fill-icon {
            display: inline;
        }
    }

    .home-fill-icon {
        display: none;
    }
}



@media screen and (max-width: 1024px) {
    .header-section {
        &.is-sticky {
            #header nav .nav_link {
                color: $clr-white;

                img {
                    filter: invert(0);
                }
            }

            .header-profile {
                .user-name {
                    color: $clr-white;

                    img.down-arrow {
                        filter: invert(1);
                    }
                }
            }
        }
    }

    .profile-menu-dropdown {
        right: auto;
        left: 0;

        ul {
            li {
                text-align: left;
            }
        }
    }

    .header-profile {
        justify-content: flex-start;
    }

    .mobile-notify {
        display: block;
        position: absolute;
        right: 80px;
        top: 38px;

        img {
            filter: invert(1) !important;
        }
    }

    .nav_item {
        .notify-head {
            display: none !important;
        }
    }

    .home-icons {
        display: none;
    }

    .home-txt {
        display: inline;
    }

    .nav_item {
        .nav_link {
            &.active-item {
                color: #017C43 !important;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .header-section.is-sticky #header {
        padding-top: 0;
    }
}