.btn-common {
    font-size: $fs-normal;
    font-weight: $semibold;
    outline: none;
    border: 0;
}

.secondary-btn {
    padding: 15px 35px;
    background-color: $clr-white;
    @include border-radius(30px);

}

.primary-btn {
    padding: 15px 35px;
    background: linear-gradient(53deg, #017C43 17.77%, #70BF44 77.8%);
    @include border-radius(30px);

    &.btn-common {
        color: $clr-white;
    }
}

.radio-group {
    display: flex;
    grid-gap: 30px;
    padding-top: 20px;
    margin-top: 20px;
    flex-direction: column;

    .radio-label {
        color: $sec-txt;
        cursor: pointer;
        @include flex-center;
        grid-gap: 10px;
        font-weight: $medium;
    }

    .custom-radiobutton {
        height: 17px;
        width: 17px;
        border-radius: 50%;
        outline: 2px solid $sec-txt;
    }

    .radiobutton-input {
        display: none;

        &:checked+span {
            border: 3px solid white;
            background: $success;
            outline: 2px solid $success;

            &+span {
                font-weight: $bold;
                color: $success;
            }
        }
    }
}

.outline {
    padding: 12px 35px;
    background: linear-gradient(white, white) padding-box,
        linear-gradient(175deg, #017C43 17.77%, #70BF44 77.8%) border-box;
    border: 2px solid transparent;
    @include border-radius(30px);

    span {
        @include text-Gradient(186deg, -23%, 114.62%);
    }
}


.spinner {
    width: 36px;
    height: 36px;
    border: 10.2px #fff double;
    border-left-style: solid;
    border-radius: 50%;
    margin: 0 auto;
    animation: spinner-aib1d7 0.75s infinite linear;
}

.auth-btn {
    &.spinner-btn {
        .btn-common {
            width: auto !important;
            height: auto !important;
        }
    }
}

.load-more-button {
    @include justify-center;

    .spinner-btn {
        @include flex-center;
        cursor: text;

        .spinner {
            width: 30px;
            height: 30px;
        }
    }
}

@keyframes spinner-aib1d7 {
    to {
        transform: rotate(360deg);
    }
}