// User Loginn Screen
.auth-screen {
    background: $clr-white;
    height: 100vh;
    overflow-x: hidden;

    .auth-overlayshape {

        // position: relative;
        &::before {
            content: '';
            position: absolute;
            width: 40%;
            height: 100%;
            left: 0;
            top: 0;
            @include bg-Gradient(186deg, -23%, 114.62%);
            border-radius: 0px 200px 0px 0px;
            // z-index: -1;
        }
    }

    .row {
        align-items: center;
        height: 100%;

        .cont-bg {
            z-index: 1;
            position: relative;
            right: -100px;
            text-align: right;
            transform: scale(0.85);

            .ripple-small {
                left: 18.5%;
                bottom: 55%;
                background: $loginripple;

                &.right {
                    right: -10px;
                    left: auto;
                    bottom: 33%;
                }

                &:before {
                    background: #FF6565;
                }
            }
        }
    }

    .app-logo {
        position: absolute;
        top: 60px;
        left: 60px;
        display: inline-block;
    }

    .auth-form {
        width: 80%;
        margin: 0 auto;
        text-align: center;

        .welcome-cont {
            p {
                max-width: 80%;
                margin: 0 auto;
            }
        }

        .tabs-group {
            display: flex;
            justify-content: space-around;
            margin-top: 20px;
            padding-top: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid $input-border;

            .tab-item {
                position: relative;

                .pro-label {
                    font-size: $fs-mini;
                    font-weight: $medium;
                    color: $sec-txt;
                    min-width: 250px;
                    cursor: pointer;
                }

                &.active-tab {
                    .pro-label {
                        font-weight: $semibold;
                        @include text-Gradient(90deg, 3.42%, 100%);
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 8px;
                        left: 0;
                        bottom: -25px;
                        @include border-radius(10px);
                        @include bg-Gradient(90deg, 0%, 100%);
                    }
                }
            }
        }

        form {
            margin-top: 30px;
            padding-top: 30px;
        }

        .back-to {
            margin-top: 30px;

            a {
                @include text-Gradient(3deg, 8.24%, 89.67%);
                font-weight: $semibold;
                text-decoration: underline;
            }
        }

        h2 {
            padding-bottom: 20px;
            font-weight: $semibold;
        }

        .forgot {
            @include text-Gradient(3deg, 8.24%, 89.67%);
        }
    }

    .auth-btn {
        margin-top: 25px;
        padding-top: 25px;

        .primary-btn.btn-common {
            width: 100%;
            height: 60px;
        }
    }

    button#password-addon {
        border: 0;
        background: transparent;
        position: absolute;
        top: 15px;
        right: 10px;
    }
}

.countdown-text {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 5px;

    .resend-code {
        margin-left: 5px;
    }
}

// Advisor Login Screen

.progress-tab {
    margin-top: -35px;

    .tabs-group {
        @include flex-between;

        .tab-item {
            @include flex-center;
            background: $clr-white;
            border-radius: 30pc;
            min-width: 205px;

            .tabcount {
                @include box-size(50px, 50px);
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                border-radius: 30px;
                margin: 8px;
                background: #E9F6EE;

                span {
                    font-weight: bold;
                    @include text-Gradient(186deg, -23%, 114.62%)
                }

                .tab-success {
                    display: none;
                }
            }

            .pro-label {
                font-size: $fs-milli;
                font-weight: $medium;
                color: $general-text-color;
            }

            &.active-tab {
                .tabcount {
                    @include bg-Gradient(3deg, 8.24%, 89.67%);

                    span {
                        color: $clr-white;
                        -webkit-text-fill-color: unset;
                    }
                }

                .pro-label {
                    color: $sec-txt;
                }
            }

            &.success {
                .tabcount {
                    @include bg-Gradient(3deg, 8.24%, 89.67%);

                    span {
                        display: none;
                    }

                    .tab-success {
                        display: flex;
                    }
                }
            }
        }
    }
}

.progress-line {
    width: 100%;
    height: 1px;
    background: $input-border;
}

.tab-body {
    padding-top: 25px;
    margin-top: 20px;

    .tab-content {
        padding: 40px;
        background: $clr-white;
        @include border-radius(30px);

        h5 {
            font-size: $fs-h5;
            color: $general-text-color;
            font-weight: $medium;
            padding-bottom: 25px;
        }
    }
}


.advisor-signup {
    // .common-banner {
    //     .banner-bg {
    //         height: 350px;
    //     }
    // }

    form {
        .form-group {
            display: flex;
            grid-gap: 23px;
        }
    }

    .message-box {
        width: 50%;

        .form-control {
            resize: none;
            height: 135px;
        }
    }

    .number-grp {
        width: 50%;
    }
}

.upload-container {
    border: 1px dashed $input-border;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    position: relative;
    left: 0;
    @include border-radius(20px);
    height: 210px;

    input {
        opacity: 0;
        position: absolute;
        width: 100%;
        left: 0;
        height: 100%;
        top: 0;
        cursor: pointer;
    }

    .upload-icon {
        @include box-size(60px, 60px);
        background: #E9F6EE;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        margin: 0 auto;
    }

    .upload-content {
        @include flex-center-column;
        justify-content: center;
        height: 100%;

        h5 {
            margin-top: 15px;
            margin-bottom: 8px;
            font-size: $fs-normal;
            font-weight: $semibold;
        }
    }

    &.sebi-no {
        height: 290px;
    }
}

.inpt-item {
    &.id-num {
        .inpt-label {
            top: 100px !important;
        }

        input.form-control:focus+.inpt-label {
            top: 76px !important;
        }

        input.form-control.valid-field+.inpt-label {
            top: 76px !important;
        }
    }

}

.tab-content {
    .outline {
        margin-right: 25px;
    }

    .kyc-gtn {
        justify-content: space-between;
    }
}

.advisor-success {
    margin-top: -30px;

    h2 {
        padding-bottom: 15px;
    }

    .bt-home {
        padding: 50px 0;
    }
}

.kyc-details-tab {
    .drop-down [class*=-control] {
        height: 55px;
        background: $white;
        border: 1px solid $input-border;
        font-size: $fs-normal;
    }

    .is-invalid {
        .drop-down [class*=-control] {
            border: 1px solid $error;
        }
    }
}


.ad-login {
    padding: 50px 0;
    display: flex;

    .login-btn {
        padding-top: 15px;
        margin-top: 15px;
    }

    .ad-login-cont {
        width: 50%;

        p {
            padding: 0 20px;
        }

        &:first-child {
            border-right: 1px solid $input-border;
        }
    }
}

.backto-home {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
    background: #f7eeee;
    padding: 5px 10px;
    border-radius: 8px;
}

@media only screen and (max-width: 1540px) {
    .auth-screen {
        .app-logo {
            left: 40px;
            top: 40px;
        }

        .row {
            .cont-bg {
                transform: scale(0.70);
                right: -24%;

                // img {
                //     width: 80%;
                // }
                .ripple-small {
                    left: -10px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1366px) {
    .auth-screen {
        .row {
            .cont-bg {

                // img {
                //     width: 65%;
                // }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .auth-screen {
        .auth-overlayshape {
            &::before {
                width: 100%;
                height: 300px;
                border-radius: 0 0 100px 100px;
            }
        }

        .row {
            align-items: flex-start;

            .col-lg-5 {
                display: none;
            }

            .cont-bg {
                display: none;
            }
        }

        .auth-form {
            background: $clr-white;
            padding: 35px;
            position: relative;
            top: 100px;
            z-index: 3;
            @include border-radius(30px);
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        }

        .app-logo {
            position: relative;
            top: 30px;
            left: 0;
            text-align: center;
            z-index: 3;
            display: block;
        }
    }

    .upload-container {
        margin-top: 20px;

        &.sebi-no {
            height: 210px;
        }
    }

    .mob-res {
        .form-group {
            flex-direction: column;
        }
    }
}

@media only screen and (max-width: 768px) {
    .progress-line {
        display: none;
    }

    .progress-tab {
        .tabs-group {
            .tab-item {
                display: none;

                &.active-tab {
                    display: flex;
                }
            }
        }
    }

    .advisor-signup {
        .common-banner {
            .banner-bg {
                height: auto;
            }
        }
    }
}

@media only screen and (max-width: 660px) {
    .advisor-signup {

        .number-grp,
        .message-box {
            width: 100%;
        }

        form {
            .form-group {
                flex-direction: column;
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .auth-screen {
        .auth-form {
            width: 95%;
            top: 60px;

            form {
                margin-top: 15px;
                padding-top: 15px;
            }

            .tabs-group {
                .tab-item {
                    .pro-label {
                        min-width: 170px;
                    }
                }
            }

            .welcome-cont {
                p {
                    max-width: 100%;
                }
            }
        }

        .auth-btn {
            margin-top: 15px;
            padding-top: 15px;
        }
    }

    .ad-login {
        flex-direction: column;
        grid-gap: 20px;

        .ad-login-cont {
            width: 100%;

            &:first-child {
                border-right: 0;
                border-bottom: 1px solid #ccc;
                padding-bottom: 25px;
            }
        }
    }
}