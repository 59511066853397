.page-footer {
    // padding-top: 100px;
    border-radius: 0px 140px 0px 0px;
    background: $footerBg;
    color: $footer-text;
    margin-top: 50px;

    .foot-one {
        display: flex;
        justify-content: space-between;
        padding-bottom: 25px;
        margin-bottom: 25px;
    }

    .logo-part {
        p {
            max-width: 235px;
            line-height: 22px;
        }
    }

    .foot-title {
        font-size: $fs-h5;
        color: $clr-white;
        font-weight: 500;
        padding-bottom: 25px;
    }

    .footer-menu {
        display: flex;
        justify-content: space-between;
        grid-gap: 60px;

        ul {
            li {
                a {
                    color: $footer-text;
                    font-weight: 500;
                }

                padding-bottom: 10px;
            }
        }
    }

    .ads-blog {
        p {
            max-width: 235px;
            line-height: 23px;
        }
    }

    hr {
        border: 1px solid $footer-text;
        margin: 0;
    }

    .copy-right {
        align-items: center;
        padding: 35px 0;

        a {
            color: $footer-text;
            text-decoration: none;
        }
    }

    .social-links {
        float: right;

        ul {
            li {
                @include box-size(40px, 40px);
                background: $clr-white;
                @include justify-center;
                margin-right: 14px;
                transition: all 0.5s;
                transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
                position: relative;
                overflow: hidden;

                &::before {
                    content: '';
                    width: 120%;
                    height: 120%;
                    position: absolute;
                    transform: rotate(45deg);
                    background: linear-gradient(53deg, #017C43 17.77%, #70BF44 77.8%);
                    top: 90%;
                    left: -110%;
                    transition: all 0.5s;
                }

                &:last-child {
                    margin-right: 0;
                }

                img {
                    filter: invert(1);
                    opacity: 0.4;
                    padding-top: 5px;
                    transition: all 0.5s;
                }

                &:hover {
                    img {
                        filter: invert(0);
                        opacity: 1;
                        transition: all 0.5s;
                    }

                    &:before {
                        top: -10%;
                        left: -10%;
                    }
                }

            }
        }
    }

    .disclaimer {
        margin: 25px 0;
        padding: 25px 0;

        p {
            span {
                font-weight: $bold;
            }
        }
    }
}

.shape-container {
    @include border-radius(30px);
    background: var(--Linear, linear-gradient(180deg, #FBBF3B 0%, #FB893B 100%));
    position: relative;
    top: -65px;

    .footer-shape {
        padding: 38px 50px;
        background: url('../../images/shape.svg') no-repeat right;
        @include flex-between;
    }

    h2 {
        color: $clr-white;
        font-size: $fs-h2;
        font-weight: $medium;
    }

    p {
        font-weight: 400;
        color: $clr-white;
        padding-top: 2px;
    }
}

@media only screen and (max-width: 1024px) {
    .shape-container {
        .footer-shape {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            grid-gap: 20px;
            background-size: cover;
            background-position: bottom center;
            border-radius: 30px;
        }
    }
}

@media only screen and (max-width: 992px) {
    .page-footer {
        .row {
            &.copy-right {
                text-align: center;
                @include flex-center-column;
                justify-content: center;
                grid-gap: 20px;
                flex-direction: column-reverse;

                .social-links {
                    float: none;

                    ul {
                        justify-content: center;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .page-footer {
        .foot-one {
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        .footer-menu {
            margin-left: 35px;
        }

        .ads-blog {
            margin-top: 25px;
        }
    }
}

@media only screen and (max-width: 576px) {
    .page-footer {
        border-radius: 0px 60px 0px 0px;

        .foot-one {
            flex-direction: column;
            grid-gap: 25px;
        }

        .footer-menu {
            display: flex;
            justify-content: flex-start;
            grid-gap: 35px;
            margin-left: 0px;
        }

        .foot-title {
            padding-bottom: 20px;
        }

        .ads-blog {
            margin-top: 0;
        }
    }
}