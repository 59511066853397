.modal {
    &.show {
        backdrop-filter: blur(8px);
        background: rgba(0, 0, 0, 0.30);
    }

    .modal-content {
        @include border-radius(10px);

        .page-title {
            font-weight: 600;
        }
    }

    .modal-header {
        position: relative;

        span.close-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
        }
    }
}

//view tips modal
.view-tips-modal {
    .modal-content {
        padding: 20px 25px;
        border-radius: 15px;

        .modal-header {
            .head-item {
                .page-title {
                    font-size: $fs-normal;
                    font-weight: $semibold;
                    line-height: 20px;
                    text-transform: capitalize;
                }
            }

            span.close-icon {
                top: 0px;
                right: 0px;
            }
        }

        .modal-body {
            padding: 0;
            max-height: 500px;
            overflow: auto;

            .tip-content {
                .blog-header {
                    @include flex-center;

                    .stock-tiptype {
                        @include border-radius(30px);
                        padding: 0px 11px;
                        color: $clr-white;
                        font-size: $fs-micro;
                        font-weight: $regular;
                        text-transform: capitalize;
                    }

                    .sell {
                        background: $by-gb;
                    }

                    .buy {
                        background: $success;
                    }

                    .market-info {
                        background: $yellow;
                    }

                    .valuable-ideas {
                        background: $blue;
                    }

                    .main-cat {
                        font-size: $fs-milli;
                        font-weight: $semibold;

                        .sub-cat {
                            font-size: $fs-mini;
                            border-radius: 10px;
                            padding: 2px 7px;
                            border: 1px solid #A6A6A6;
                            color: #A6A6A6;
                            font-weight: $medium;
                        }
                    }

                    ul {
                        @include flex-center;

                        li {
                            margin-right: 15px;
                        }
                    }
                }

                .descriptions {
                    h5 {
                        font-weight: $medium;
                        text-wrap: wrap;
                        line-height: 23px;
                    }

                    .date-time {
                        font-size: $fs-micro;
                        text-align: right;
                        padding-right: 15px;
                    }
                }

                .tips-auth-info {
                    width: 100%;
                    padding-top: 10px;
                    @include flex-between;

                    .ad-profile {
                        @include box-size(20px, 20px);
                        background: $body-color;
                        @include border-radius(30px);
                        @include justify-center;
                        margin-right: 8px;

                        span {
                            font-size: $fs-mini;
                            text-align: center;
                            font-weight: $semibold
                        }
                    }

                    .ad-name {
                        font-weight: $medium;
                        font-size: $fs-mini;
                    }
                }
            }
        }
    }
}