body {
  font-family: $ff-base;
  font-size: $fs-base;
  color: $general-text-color;
  background: $body-color;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

p {
  line-height: 23px;
}

.wrapper {
  overflow-x: hidden;
}

.gradient-txt {
  @include textGradient;
  font-weight: $semibold;
}

.card {
  border: 0;
  @include border-radius(30px);
  background: $clr-white;
}

.large-cap {
  padding: 150px 0;
}

.medium-cap {
  padding: 75px 0;
}

.gray-bg {
  background: $grayBg;
}

h2 {
  color: $sec-txt;
  font-size: $fs-h2;
  font-weight: $regular;
}

h4 {
  color: $sec-txt;
  font-size: $fs-h4;
  font-weight: $semibold;
}

/* Ripple*/
.image-ripple-blog {
  position: relative;
  display: inline-block;
}

.ripple-large,
.ripple-small {
  @include flex-center;
  justify-content: center;
  @include rippleBg;
  @include box-size(65px, 65px);
  border-radius: 50%;
  position: absolute;
  top: 80px;
  right: -30px;

  img {
    z-index: 3;
  }

  &:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    @include box-size(65px, 65px);
    background: #70BF44;
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
  }

}

.ripple-small {
  @include box-size(22px, 22px);
  right: auto;
  top: auto;
  bottom: 30%;
  left: -10px;

  &:before {
    @include box-size(25px, 25px);
  }
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}


//custom scroll
.custom-scroll {
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 30px;
    @include bg-Gradient(3deg, 8.24%, 89.67%);
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 30px;
  }
}

//input number input hide
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;

}

input[type=number] {
  overflow: hidden;
  touch-action: none;
}

.no-data {
  text-align: center;
  padding: 30px 0;

  img {
    width: 25%;
  }

  p {
    color: $sec-txt;
    font-size: $fs-normal;
    font-weight: $medium;
  }
}

.cursor-pointer {
  cursor: pointer;
}

@media only screen and (max-width: 576px) {
  .large-cap {
    padding: 80px 0;
  }

  h2 {
    font-size: 30px;

    br {
      display: none;
    }
  }
}