/*Tips Card*/
.tips-item {
  background: $clr-white;
  @include border-radius(20px);
  padding: 20px;
  min-height: 230px;
  max-width: 417px;

  .closed-text {
    display: none;
  }

  &.closed-tip {
    position: relative;
    background-color: $closed-color;


    .closed-text {
      display: block;

      p {
        position: absolute;
        top: 45%;
        left: 38%;
        font-size: $fs-milli;
        z-index: 1;
        margin-bottom: 0;
        font-weight: $semibold;
        background-color: $clr-white;
        border-radius: 8px;
        padding: 8px 15px;
      }
    }
  }

  .cat-type {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: $fs-milli;
      color: $sec-txt;
      font-weight: $semibold;
      text-transform: capitalize;

      span {
        color: $sub-cat-text;
        font-weight: 400;
        font-size: 12px;
        border: 1px solid $sub-cat-text;
        padding: 3px 13px;
        margin-left: $fs-micro;
        text-transform: capitalize;
        @include border-radius(30px);
      }
    }

    .time {
      color: $sub-cat-text;
      font-weight: 400;
      font-size: $fs-micro;
      margin-left: 10px;
    }
  }

  .badges {
    display: flex;
    align-items: center;
    padding: 5px 0 10px;

    .updated {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }
  }

  .typ-stus {
    p {
      @include border-radius(30px);
      padding: 3px 15px;
      color: $clr-white;
      font-size: $fs-micro;
      font-weight: $regular;
      text-transform: capitalize;
    }
  }

  .buy-tip {
    background: $success;
  }

  .sell-tip {
    background: $by-gb;
  }

  .market-info-tip {
    background: $yellow;
  }

  .valuable-ideas-tip {
    background: $blue;
  }

  .multi-description-flag {
    p {
      font-size: $fs-micro;
      font-weight: $regular;
    }

    background: linear-gradient(90deg, #FFA100 10.29%, #FF6B00 100%);
    color: #fff;
    padding: 3px 30px;
    @include border-radius(30px);
    clip-path: polygon(100% 0%, 100% 112%, 100% 100%, 0% 100%, 21% 50%, 0% 0%);
  }

  .tip-cont {
    min-height: 100px;

    p {
      font-weight: $semibold;
      line-height: 23px;
      text-overflow: ellipsis;
      max-height: 70px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;

    }
  }

  .advisor-info {
    @include flex-center;

    .advisor-pic {
      @include box-size(20px, 20px);
      background: $body-color;
      @include border-radius(30px);
      @include justify-center;
      overflow: hidden;

      span {
        font-size: $fs-mini;
        text-align: center;
        font-weight: $semibold;
        text-transform: uppercase;
      }
    }

    h6 {
      margin-left: 10px;
      font-size: $fs-milli;
      font-weight: $semibold;
      color: $auth-txt;
      text-transform: capitalize;
    }
  }

  .likes {
    ul {
      grid-gap: 12px;

      li {
        img {
          margin-right: 8px;
        }
      }
    }
  }
}

/*---Advisor---*/
.ad-pro {
  @include box-size(68px, 68px);
  @include border-radius(50%);
  background: $cmd-count-bg;
  @include flex-center;
  justify-content: center;
  overflow: hidden;

  h6 {
    font-weight: $semibold;
    font-size: 20px;
  }

  img {
    object-fit: fill;
    width: 100%;
  }
}

.cmt-count {
  @include box-size(50px, 50px);
  @include border-radius(30px);
  background: $cmd-count-bg;
  @include flex-center;
  position: relative;
  justify-content: center;

  span.badge {
    position: absolute;
    top: -4px;
    right: -4px;
    background: red;
    padding: 4px;
    border-radius: 30px;
    min-width: 20px;
  }
}

.ads-item {
  flex-grow: 1;
  flex-basis: 350px;
  max-width: 417px;

  .user-info {
    h5 {
      font-weight: $semibold;
      text-transform: capitalize;
    }
  }
}

@media only screen and (max-width: 480px) {
  .tips-item {
    .cat-type {
      display: block;

      .time {
        margin-left: 0;
        margin-top: 3px;
      }
    }
  }
}