.drop-down {
  &.md {
    width: 160px;
  }

  &.sm {
    width: 125px;
  }

  [class*="-indicatorSeparator"] {
    display: none;
  }

  [class*="-is-selected"] {
    @include rippleBg;
    color: $white;
  }

  [class*="-menu"] {
    border-radius: 10px;
    border: 0;
    box-shadow: 0 0 0 0px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);

    [class*="-option"] {
      cursor: pointer;

      &:hover {
        background-color: $grayBg !important;
        // color: $primary-color;
      }
    }

    [class*="-is-focused"] {
      background-color: $grayBg !important;
      //   color: $primary-color !important;
    }

    [class*="-MenuList"] {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  [class*="-control"] {
    font-size: 14px;
    border-radius: 10px;
    border: none;
    white-space: nowrap;
    text-overflow: clip;
    width: 100%;
    cursor: pointer;
    box-shadow: none;
    padding: 5px 10px;
    background: $grayBg;
    font-weight: $medium;
  }

  [class*="-singleValue"] {
    text-transform: capitalize;
  }

  [class*="placeholder"] {
    color: $filterPlaceholder;
    font-weight: $medium;
  }
}
