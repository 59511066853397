// Color Values
$darkGray: #a5a5a5;
$body-color: #F6F6F6;
// TextColors
$sec-txt: #484848;
$general-text-color: #757575;
$footer-text: #a5a5a5;
$sub-cat-text: #818181;
$auth-txt: #424B5A;
$filterPlaceholder: #ABABAB;
$light-black: #696969;
$light-gray-text: #B4B4B4;

$input-border: #BEBEBE;
$input-text: #757575;
$input-focus: #A3A3A3;
$input-label: #C8C8C8;

$over-lay: #0000006b;

//Bg Colors
$footerBg: #555555;
$grayBg: #EDEDED;
$lightGrayBg: #FBFBFB;
$cmd-count-bg: #EAF1E5;
$input-light-bg: #F9F9F9;

/*Neutrals */
$clr-white: #ffffff;
$clr-gray: #cccccc;
$clr-black: #000000;

//Status Colors
$success: #42B92E;
$error: #dc3545;
$by-gb: #FA6444;
$bullet: #059653;
// Banner Gradient
$banner-gradient: linear-gradient(186deg, #059653 -23%, #77ce46 114.62%);
$banner-bg: linear-gradient(180deg, #EDEDED 50%, #f6f6f6 50%);
// background: linear-gradient();
$role-fill-one: linear-gradient(3deg, #E5EFF5 8.24%, #FFF 89.67%);
$role-fill-two: linear-gradient(3deg, #E3FFD7 8.24%, #FFF 89.67%);
$role-fill-three: linear-gradient(3deg, #FFF5E9 8.24%, #FFF 89.67%);
$role-fill-four: linear-gradient(3deg, #FEE 8.24%, #FFF 89.67%);
$loginripple: linear-gradient(90deg, #FF6565 -0.15%, #C82F1B 99.85%);
//star progress bg
$five-star: #4AA752;
$four-star: #A5D231;
$three-star: #F7E642;
$two-star: #F8A422;
$one-star: #EE3B1A;
$progress: #0000000f;
$star: #FCBD00;
$closed-color: #afa9a938;

$blue: #3058E3;
$yellow: #FFC748;