/*Banner */
.banner-bg {
    position: relative;
    height: 100vh;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 80%;
        height: 100%;
        border-radius: 0px 0px 0px 100px;
        background: var(--green, linear-gradient(186deg, #059653 -23%, #77CE46 114.62%));
    }

    .banner-group {
        width: 75%;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        // @include flex-between;
        display: grid;
        grid-template-columns: 70% 28%;
        align-items: flex-start;
        align-content: center;
        height: 100%;
        grid-gap: 30px;

        .slider-bg {
            background: $clr-white;
            height: 100%;
            @include border-radius(30px);
            padding: 50px;
            height: 100%;

            .h-sl-itm {
                @include flex-between;
                grid-gap: 20px;
                height: 100%;

                h1 {
                    color: $sec-txt;
                    font-size: $fs-h2;
                    font-weight: $semibold;
                    max-width: 330px;
                    line-height: 37px;

                    span {
                        @include text-Gradient(270deg, 43.13%, 99.84%);
                        font-size: $fs-xlagre;
                        font-weight: $bold;
                    }
                }

                p {
                    font-size: $fs-normal;
                    line-height: 27px;
                    padding-bottom: 25px;
                    max-width: 350px;
                    padding-top: 15px;
                }
            }
        }
    }

    .banner-img {
        position: relative;
        margin: 12px 0;
        padding-right: 45px;

        // width: 100%;
        .overlay-img {
            position: absolute;
            right: 0;
            top: 0;
            /* opacity: 0.6; */
            background: $body-color;
            width: 100%;
            height: 100%;
        }
    }
}

.h-slider {
    @include flex-center;
    height: 100%;
}

/*Marketto Metter Card */
.marketto {
    padding: 30px;
    height: max-content;

    .meter-header {
        display: flex;
        grid-gap: 10px;
        align-items: center;

        p {
            font-size: 14px;
            line-height: 18px;
        }

        .primary-btn {
            font-size: 16px;
            padding: 10px 20px;
            font-weight: 400;
        }
    }

    .meter-chart {
        padding-top: 25px;
        margin: 0 auto;
    }

    .trading-time {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            span {
                font-weight: $semibold;
            }
        }
    }
}

span {
    &.sign-uplink {
        color: #41b44d;
        font-weight: 600;
        cursor: pointer;
    }
}

text.segment-value {
    font-size: 12px !important;
}

/*Live Tips Card */

.latest-tips {
    .latest-nodata {
        background-color: #FFF;
        margin-top: 20px;
        text-align: center;
        // height: 150px;
        width: 100%;
        border-radius: 30px;
    }
}

.lt-head {
    display: flex;
    align-items: center;
    margin-top: 35px;
    margin-bottom: 15px;

    p {
        background: #FF4E4E;
        padding: 3px 12px 3px 20px;
        border-radius: 6px;
        color: $clr-white;
        font-weight: $semibold;
        position: relative;
        margin-right: 8px;

        &::before {
            content: '';
            position: absolute;
            top: 11px;
            left: 8px;
            width: 6px;
            height: 6px;
            border-radius: 15px;
            background: $clr-white;
        }
    }

    h6 {
        color: $clr-white;
        font-size: 19px;
        font-weight: $medium;
    }
}

/*Services Card */
.sers-group {
    display: flex;
    width: 100%;
    max-width: 100%;
    margin-top: 25px;
    padding-top: 25px;
    grid-gap: 25px;
    flex-wrap: wrap;

    .sers-items {
        background: $clr-white;
        flex: 1 0 355px;
        padding: 40px 30px;
        @include border-radius(30px);

        .icon-large {
            @include box-size(106px, 106px);
            @include border-radius(50px);
            background: linear-gradient(3deg, #FFECEC 8.24%, #FFF 89.67%);
            margin: 0 auto;
            @include justify-center;

            &:nth-child(2) {
                background: linear-gradient(3deg, #FFF1DE 8.24%, #FFF 89.67%);

            }

            &:nth-child(3) {
                background: linear-gradient(3deg, #D9EAF8 8.24%, #FFF 89.67%);

            }
        }

        h4 {
            padding-bottom: 12px;
        }
    }
}

.tips-group {
    display: flex;
    flex-wrap: wrap;
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    // align-content: flex-start;
    grid-gap: 23px;
    margin-top: 25px;
    padding-top: 25px;

    .tips-item {
        flex-grow: 1;
        flex-basis: 350px;
    }
}

.gradient-bg {
    position: relative;
    padding: 100px 0;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 85%;
        height: 100%;
        transform: scale(-1);
        z-index: -1;
        border-radius: 80px 0px 0px 80px;
        background: linear-gradient(186deg, #059653 -23%, #77CE46 114.62%);
    }
}

.why-fideal {
    background: linear-gradient(0deg, rgba(237, 237, 237, 1) 50%, rgba(246, 246, 246, 1) 50%);
    position: relative;
    z-index: 1;

    .sers-group {
        .sers-items {
            flex: 1 0 255px;

            .icon-large {
                @include box-size(55px, 55px);
                background: $role-fill-one;
            }

            p {
                font-size: $fs-normal;
                font-weight: $semibold;
            }

            &:nth-child(2) {
                .icon-large {
                    background: $role-fill-two;
                }
            }

            &:nth-child(3) {
                .icon-large {
                    background: $role-fill-three;
                }
            }

            &:nth-child(4) {
                .icon-large {
                    background: $role-fill-four;
                }
            }
        }
    }
}

// Advisors Signup
.advisor-card {
    background: var(--Linear, linear-gradient(90deg, #348DCD -0.15%, #1D4CB8 99.85%));
    @include border-radius(30px);
}

.advisors-signup {
    background: url('../../images/svgimage/advisor-bg.svg') no-repeat top right;
    height: 222px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 80px 60px;
    border-radius: 30px;

    .signup-cont {
        max-width: 70%;

        h2 {
            color: $clr-white;
            font-size: $fs-h3;
            line-height: 34px;

            span {
                font-size: $fs-xlagre;
                font-weight: $bold;

            }
        }
    }
}

// accordion
.accordion-group {
    width: 100%;

    .accordion-items {
        position: relative;
        transition: all 0.3s ease-in-out;

        &.open-item {
            transition: all 0.3s ease-in-out;
        }
    }

    .accordion-header {
        background: $clr-white;
        @include border-radius(30px);
        padding: 10px 20px;
        margin-bottom: 10px;
        height: auto;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        h5 {
            font-weight: $semibold;
            color: $sec-txt;
            padding-left: 25px;
            line-height: 21px;
        }
    }

    .accordion-body {
        padding-left: 75px;

        .content-body {
            position: relative;
            padding-bottom: 10px;
            transition: all 0.8s ease-in-out;
        }
    }
}

.btn-grp {
    @include flex-between;
}

// Home Silder
// SliderChanges
.home-pagination {
    &.swiper-pagination-bullets {
        position: absolute;
        top: 50%;
        left: -5%;

        .swiper-pagination-bullet {
            @include box-size(10px, 10px);
            background: transparent;
            border: 1px solid #CFCFCF;
            display: block;
            margin: 10px 0;
            opacity: 1;

            &.swiper-pagination-bullet-active {
                background: #6CBD44;
                border: 1px solid #6CBD44;
            }
        }
    }

}

.latest-pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .swiper-pagination-latest {
        display: flex;
        justify-content: flex-end;

        .swiper-pagination-bullet {
            &.swiper-pagination-bullet-active {
                background: $clr-white;
            }
        }
    }
}

@media only screen and (max-width: 1600px) {
    .banner-bg {
        height: auto;
        padding: 100px 0;
    }
}

@media only screen and (max-width: 1440px) {
    .banner-bg {
        .banner-group {
            width: 85%;
            height: calc(100% - -60px);

            .slider-bg {
                padding: 40px;
            }
        }
    }

    .marketto {
        .meter-chart {
            padding-top: 8px;
        }
    }

    .lt-head {
        margin-top: 20px;
    }

    // .tips-item {
    //     max-width: 355px;
    // }
}

@media only screen and (max-width: 1366px) {
    .tips-group {
        .tips-item {
            max-width: 360px;
        }
    }
}

@media only screen and (max-width: 1150px) {
    .tips-group {
        .tips-item {
            max-width: 450px;
        }
    }
}

@media only screen and (max-width: 992px) {
    .container {
        max-width: 90%;
    }

    .banner-bg {
        height: auto;
        padding-top: 130px;
        padding-bottom: 30px;

        .banner-group {
            grid-template-columns: 100%;
        }

        .mobile-blog {
            display: grid;
            grid-template-columns: 50% 50%;
            align-items: center;
            align-content: center;
            grid-gap: 25px;
        }

        .marketto {
            .meter-chart {
                padding-top: 35px;
            }
        }
    }

    .lt-head {
        margin-top: 0;
    }

    .large-cap {
        padding: 100px 0;
    }

    .tips-group {
        .tips-item {
            flex-basis: 330px;
        }
    }

    // .ads-item {
    //     flex-basis: 330px;
    // }

    .tips-group {
        grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    }

    .gradient-bg {
        &::before {
            width: 100%;
        }
    }

    .advisors-signup {
        grid-gap: 20px;
        background-position: 90% 100%;
        background-size: cover;
        flex-direction: column;
        height: auto;
        padding: 30px;
        text-align: center;
    }
}

@media only screen and (max-width: 768px) {
    .banner-bg {
        .mobile-blog {
            display: block;
        }

        &::after {
            width: 100%;
            border-radius: 0px 0px 0px 60px;
        }

        .banner-group {
            .slider-bg {
                .h-sl-itm {
                    flex-direction: column-reverse;
                    align-items: flex-start;
                }
            }
        }
    }

    .tips-group {

        // grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        .ads-item {
            max-width: 100%;
        }

        .tips-item {
            max-width: 100%;
        }
    }

    .accordion-group {
        padding-top: 30px;
    }

    .tips-item {
        max-width: 100%;
    }

    .home-pagination.swiper-pagination-bullets {
        top: auto;
        left: 43%;
        display: flex;
        width: 20%;
        margin-bottom: 20px;

        .swiper-pagination-bullet {
            display: flex;
            margin: 0 10px;
        }
    }

    .latest-tips {
        margin-top: 25px;
    }
}


@media only screen and (max-width: 576px) {
    .container {
        width: 95%;
    }

    .banner-bg {

        .lt-head {
            margin-top: 20px;
        }

        .primary-btn {
            margin-bottom: 30px;
        }
    }

    .sers-group {
        flex-direction: column;
        grid-gap: 20px;
        margin-top: 15px;
        padding-top: 15px;
    }

    .banner-bg {
        .banner-group {
            display: block;
            width: 90%;

            .slider-bg {
                padding: 30px;
                margin-bottom: 30px;

                .h-sl-itm {
                    flex-direction: column-reverse;
                }
            }
        }
    }

    .advisors-signup {
        flex-direction: column;
        height: auto;
        padding: 30px;
        text-align: center;
        grid-gap: 20px;
        background-size: cover;
        background-position: bottom right;

        .signup-cont {
            max-width: 100%;

            h2 {
                font-size: 23px;
                line-height: 28px;
            }
        }
    }

    .gradient-bg {
        &::before {
            width: 100%;
        }
    }

    .sers-group .sers-items {
        flex: 100%;
    }

    .why-fideal {
        .sers-group {
            .sers-items {
                flex: 100%;
            }
        }
    }

    .accordion-group .accordion-body {
        padding-left: 20px;
    }

    .tips-item,
    .ads-item {
        max-width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .btn-grp {
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        grid-gap: 20px;
    }

    .banner-img {
        width: 90%;
        display: block;
        margin: auto !important;
    }
}