

@mixin textGradient {
    background: linear-gradient(56deg, #017C43 13.32%, #70BF44 50.06%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}  
@mixin text-Gradient($deg, $colVal_A, $colVal_B ) {
    background: linear-gradient($deg, #017C43 $colVal_A, #70BF44 $colVal_B);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
} 
@mixin rippleBg {
    background: linear-gradient(186deg, #059653 -23%, #77CE46 114.62%);
} 

@mixin bg-Gradient($deg, $colVal_A, $colVal_B ) {
    background: linear-gradient($deg, #017C43 $colVal_A, #70BF44 $colVal_B);
} 

@mixin border-radius($pixel) {
    border-radius: $pixel;
}

@mixin gradient-bgcolor{
    background: linear-gradient(270deg, #FBBF3B 0%, #FB893B 100%);
}
//Flex
@mixin flex-center {
    display: flex;
    align-items: center;
}

@mixin flex-between {
    @include flex-center;
    justify-content: space-between;
}
@mixin justify-center{
    @include flex-center;
    justify-content: center;
}
@mixin flex-center-column {
    @include flex-center;
    flex-direction: column;
}

//Size
@mixin box-size($width, $height) {
    width: $width;
    height: $height;
}
