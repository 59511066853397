.about-us {
    h2 {
        margin-bottom: 27px;
    }

    p {
        font-weight: $medium;
        color: $general-text-color;
        margin-bottom: 27px;
    }

    .ripple-content {
        text-align: center;
    }

    .list-container {
        grid-template-columns: unset;
        margin-top: 0;
        grid-gap: unset;

        .list-item {
            margin-bottom: 15px;
            padding-left: 30px;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                width: 25px;
                height: 25px;
                top: 0;
                left: 0;
                background: url('../../images/svgicons/tick.svg') no-repeat;
                background-size: 80%;
            }

            p {
                width: unset;
            }
        }
    }

    .ripple-large {
        top: 155px;
    }
}

.VM-bg {
    background-color: $grayBg;

    .ripple-content {
        text-align: center;
    }

    .content-right {
        .content-vm {
            display: flex;
            align-items: flex-start;
            margin-bottom: 78px;

            img {
                margin-right: 41px;
            }

            h2 {
                margin-bottom: 27px;

                span {
                    font-weight: $semibold;
                }
            }

            p {
                font-size: $fs-base;
                font-weight: $medium;
                color: $general-text-color;
            }
        }
    }
}

.card-bg {
    position: relative;
    background-image: $banner-bg;
    z-index: 1;

    h1 {
        font-size: $fs-h2;
        font-weight: $regular;
        color: $clr-white;
        margin-bottom: 34px;

        span {
            font-weight: $semibold;
        }
    }

    p {
        color: $clr-white;
        max-width: 62%;
        margin: 0 auto;
        padding-bottom: 30px;
    }

    .row {
        .col-md-3 {
            .roles-card {
                background-color: $clr-white;
                border-radius: 30px;
                height: 100%;
                padding: 40px;

                .img-bg-one {
                    width: 56px;
                    height: 56px;
                    border-radius: 100%;
                    display: grid;
                    place-items: center;
                    margin: auto;
                }

                h6 {
                    font-size: $fs-normal;
                    font-weight: $semibold;
                    color: $sec-txt;
                    margin: 15px 0 11px 0;
                }

                p {
                    width: unset;
                    font-size: $fs-base;
                    font-weight: $medium;
                    color: $general-text-color;
                    line-height: 21px;
                    margin-bottom: 0;
                    max-width: 100%;
                }
            }

            &:nth-child(1) .img-bg-one {
                background-image: $role-fill-one;
            }

            &:nth-child(2) .img-bg-one {
                background-image: $role-fill-two;
            }

            &:nth-child(3) .img-bg-one {
                background-image: $role-fill-three;
            }

            &:nth-child(4) .img-bg-one {
                background-image: $role-fill-four;
            }
        }
    }
}


.team-info {
    h2 {
        margin-bottom: 60px;
    }

    .thumb-info {
        background-color: $clr-white;
        border-radius: 32px;

        .member-img {
            border-radius: 32px 32px 0 32px;
            overflow: hidden;
            position: relative;

            img {
                width: 100%;
                transition: all 0.8s;
            }

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                // background-color: $over-lay;
                width: 100%;
                height: 0;
                @include justify-center;
                transition: all 0.8s;
                opacity: 0;
                background: rgb(0, 0, 0, 0);
            }

            .circle {
                width: 30px;
                height: 30px;
                border: 1px solid $clr-white;
                border-radius: 100%;
                display: grid;
                place-items: center;
                margin-right: 10px;

                img {
                    width: unset;
                    display: grid;
                    place-items: center;
                    transform: rotate(0deg) scale(1) !important;
                }

            }


            &:hover {
                img {
                    transform: rotate(10deg) scale(1.3);
                }

                .overlay {
                    opacity: 1;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.5);
                }

                // .circle:nth-child(1) {
                //     transition: .3s 0s;
                // }

                // .circle:nth-child(2) {
                //     transition: .3s .05s;
                // }

                // .circle:nth-child(3) {
                //     transition: .3s .1s;
                // }
            }

            .circle {
                img {
                    width: unset;
                }
            }
        }

        .member-name {
            padding: 32px 0;

            h5 {
                font-size: $fs-normal;
                font-weight: $semibold;
                color: $sec-txt;
                line-height: 21px;
            }

            p {
                font-size: $fs-mini;
                font-weight: $medium;
                color: $input-border;
            }
        }
    }

    .swiper-button-prev {
        position: absolute;
        top: 35%;

        &:after {
            content: url(../../images/svgicons/Arrows_Left.svg);
            rotate: 180deg;
        }
    }

    .swiper-button-next {
        position: absolute;
        top: 35%;

        &:after {
            content: url(../../images/svgicons/Arrows_Left.svg);
        }
    }


    .swiper-pagination {
        position: unset;
        margin-top: 20px;
    }

    .swiper-pagination-bullet-active {
        background: $bullet;
    }
}

@media screen and (max-width:1024px) {
    .about-us {
        p {
            margin-bottom: 15px;
        }

        .col-md-6 {
            width: 100%;

            .content-right {
                img {
                    padding-left: 0;
                }

                margin-top: 30px;
                display: grid;
                place-items: center;
            }
        }
    }

    .VM-bg {
        .image-ripple-blog {
            padding-top: 20px;
        }

        .row {
            flex-direction: column-reverse;

            .col-md-6 {
                width: 100%;

                .content-left {
                    display: grid;
                    place-items: center;
                }

                .content-right {
                    .content-vm {
                        margin-bottom: 50px;
                    }
                }
            }
        }

        .ripple-large {
            right: auto;
            left: -30px;
            bottom: 30%;
            top: auto;
        }

        .ripple-small {
            top: 80px;
            bottom: auto;
            left: auto;
            right: -10px;
        }
    }

    .card-bg {
        .col-md-3 {
            margin-top: 30px;
            width: 50% !important;
        }

        p {
            width: unset;
            max-width: 100%;
            padding-bottom: 0;
        }
    }
}

@media screen and (max-width:820px) {

    .team-info {
        h2 {
            margin-bottom: 30px;
        }

        .thumb-info {
            .member-img {
                margin-top: 30px;

                img {
                    width: 100%;
                }
            }

            .member-name {
                padding: 20px 0;

                h5 {
                    font-size: $fs-milli;
                }

                p {
                    font-size: $fs-micro;
                }
            }
        }
    }
}


@media screen and (max-width:768px) {
    .about-us {
        p {
            margin-bottom: 25px;
        }
    }

    .VM-bg {
        .row {
            flex-direction: column-reverse;

            .content-right {
                .content-vm {
                    margin-bottom: 50px
                }
            }
        }

    }

    .card-bg {
        h1 {
            font-size: $fs-h4;
        }

        .col-md-3 {
            margin-top: 30px;
            width: 50% !important;
        }
    }

    .team-info {
        .thumb-info {
            .member-img {
                img {
                    width: 100%;
                }
            }

            .member-name {
                padding: 20px 0;

                h5 {
                    font-size: $fs-milli;
                }

                p {
                    font-size: $fs-micro;
                }
            }
        }
    }
}

@media screen and (max-width:576px) {

    .VM-bg {
        .content-right {
            .content-vm {
                flex-direction: column;
                text-align: center;

                img {
                    width: 100px;
                    margin: auto;
                }

                p {
                    width: 100%;
                    margin: auto;
                }

                h2 {
                    margin-top: 27px;
                }
            }
        }
    }

    .card-bg {
        .col-md-3 {
            margin-top: 30px;
            width: 100% !important;
        }
    }

    .team-info {
        .thumb-info {
            .member-img {
                margin-top: 0px;
            }
        }

        .col-md-3 {
            margin-top: 0;
            width: 100%;
        }
    }
}