// review card
.review-group {
  border-radius: 20px;
  background: $white;
  padding: 40px;

  .review-item {
    .user-review {
      border-bottom: 1px solid $grayBg;
      padding: 30px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }

      .item-header {
        @include flex-between;

        .user {
          @include flex-center;
        }

        .date-time {
          @include flex-center;
          font-size: $fs-base;
          color: $light-gray-text;

          .date {
            padding-right: 12px;
          }

          .time {
            font-size: $fs-micro;
          }
        }

        .star {
          border-radius: 30px;
          padding: 8px 16px;
          color: $white;
          font-size: $fs-base;
          font-weight: $medium;
          min-width: 50px;
          max-width: fit-content;

          &.star-5 {
            background: $five-star;
          }

          &.star-4 {
            background: $four-star;
          }

          &.star-3 {
            background: $three-star;
          }

          &.star-2 {
            background: $two-star;
          }

          &.star-1 {
            background: $one-star;
          }

          img {
            width: 12px;
          }
        }

        .title {
          color: $auth-txt;
          font-size: $h3-small;
          padding-left: 12px;
          margin-left: 13px;
          font-weight: $medium;
        }
      }

      .item-body {
        color: $general-text-color;
        font-size: $fs-base;
        font-weight: $medium;
        padding-top: 23px;
        line-height: 24px;
        word-wrap: break-word;
      }
    }
  }

  .load-more-btn {
    text-align: center;
    padding-top: 20px;
    margin-top: 20px;
  }
}

//react star ratng package
.star-ratings {
  width: 90px !important;
  display: flex !important;
  justify-content: space-between;
  padding-top: 5px;

  .star-container {
    padding: 0 !important;

    .widget-svg {
      width: 15px !important;
      height: unset !important;
      stroke: $star !important;
      stroke-width: 3px;
    }
  }
}

@media only screen and (max-width: 576px) {
  .review-group {
    .review-item {
      .user-review {
        .item-header {
          flex-direction: column;
          align-items: flex-start;

          .date-time {
            padding-top: 15px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 460px) {
  .review-group {
    padding: 25px;

    .review-item {
      .user-review {
        .item-header {
          .user {
            flex-direction: column;
            align-items: flex-start;

            .title {
              margin-left: 0;
              padding-left: 0;
              padding-top: 15px;
              font-size: 25px;
            }
          }
        }
      }
    }
  }
}