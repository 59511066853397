.profile {
    .card {
        @include border-radius(30px);
        padding: 45px;

        .title {
            font-size: $fs-h5;
            font-weight: $semibold;
            margin-bottom: 30px;
        }

        input.form-control:focus+.inpt-label,
        input.form-control.valid-field+.inpt-label,
        textarea#message:focus+.inpt-label,
        textarea#message.valid-field+.inpt-label {
            font-size: 14px;
            top: -5px;
            background: $clr-white;
            padding: 0px 8px;
        }

        .form-control.is-invalid {
            border-color: $error;
            box-shadow: none !important;
        }
    }

    button#password-addon {
        border: 0;
        background: transparent;
        position: absolute;
        top: 15px;
        right: 8px;
    }
}

.profile-edit {
    width: 210px;
    height: 210px;
    border-radius: 50%;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
}

@media(max-width: 575px) {
    .profile {
        .card {
            padding: 30px;
        }
    }
}