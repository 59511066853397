
$ff-base: 'Montserrat', sans-serif;

//FontSize

/* heading font sizes */
$fs-h1: 55px;
$fs-h2: 36px;
$fs-h3: 30px;
$fs-h4: 24px;
$fs-h5: 20px;
// $fs-h6: 20px;
$fs-xlagre: 47px;
$fs-xmedium: 41px;
/* base font size - applied at body / html level */
$fs-base: 16px;

/* Smaller than heading font sizes */
$fs-normal: 18px;
$fs-milli: 15px;
$fs-mini: 14px;
$fs-micro: 12px;
$fs-nano:  8px;
$fs-h1-large: 45px;
$h3-small: 28px;
$h5-big: 22px;


/*  -font-weights- */
$thin: 100;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;

