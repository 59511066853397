.custom-input {
  width: 250px;
  padding: 14px 18px;
  border-radius: 13px;
  background: $grayBg;
  border: none;
  outline: none;
}

.search-input {
  position: relative;
  font-size: 14px;
  width: 250px;

  ::placeholder {
    color: $filterPlaceholder;
    font-weight: $medium;
  }

  input {
    padding-left: 40px;
    width: 100%;
  }

  .search-icon {
    position: absolute;
    left: 15px;
    top: 35%;
  }
}

textarea {
  &.custom-input {
    resize: none;
    height: 102px;
    width: 100%;
    background: $input-light-bg;
  }
}