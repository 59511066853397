.form-control{
    border: 1px solid $input-border;
    @include border-radius(12px);
    height: 55px;
    font-size: $fs-normal;
    color: $sec-txt;
    font-weight: $medium;
    padding: 8px 20px;
    &:focus{
        box-shadow: none;
    }
  &.is-invalid{
    background-image: none;
  }
}
.inpt-item{
    position: relative;
    width: 100%;
    .inpt-label{
        position: absolute;
        cursor: text;
        top: 20px;
        left: 20px;
        color: $sec-txt;
        font-weight: $medium;
        color: #C8C8C8;
        opacity: unset;
        transition: all 0.3s ease;
    }
}

/* Chrome, Safari, Edge, Opera */
input{
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
}
input.form-control:focus + .inpt-label,
input.form-control.valid-field + .inpt-label,
textarea#message:focus + .inpt-label,
textarea#message.valid-field + .inpt-label {
    font-size: 11px;
    top: -5px;
    background: $clr-white;
    padding: 0px 8px;
  }

.form-control.is-invalid {
    border-color: $error;
    box-shadow: none !important;
}

/* Firefox */
input[type=number] {
  --moz-appearance: textfield;
}
.form-layout{
    @include flex-center;
    justify-content: space-between;
}
.invalid-feedback{
    text-align: left;
    position: relative;
    padding-left: 20px;
    &::before{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 14px;
        width: 14px;
        background: url('../../images/svgicons/alert-circle.svg') no-repeat;
        background-size: cover;
    }
}