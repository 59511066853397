.common-banner {
  .banner-bg {
    height: 450px;
    @include justify-center;

    .text-container {
      position: relative;
      color: $white;
      z-index: 1;
      @include justify-center;

      .text-wrapper {
        width: 70%;
        padding-left: 30px;

        .title {
          font-size: $fs-h1;
          font-weight: $bold;
          text-transform: capitalize;
        }

        .description {
          line-height: 23px;
          padding-top: 15px;
        }
      }

      &.view-advisor-banner {
        .text-wrapper {
          width: 100%;
        }
      }
    }
  }

  .container {
    max-width: 70%;
  }
}

.breathing-circle {
  @include box-size(280px, 280px);
  @include border-radius(50%);
  position: relative;
  overflow: hidden;
  left: -13%;
  padding: 35px;
  background: linear-gradient(3deg, rgba(1, 124, 67, 0.18) 8.24%, rgba(112, 191, 68, 0.18) 89.67%);
  font-size: 150px;
  @include justify-center;
  // .image-overlay {
  //   position: absolute;
  //   inset: (0 0 0 0);
  //   display: block;
  //   @include box-size(230px, 230px);
  //   @include border-radius(50%);
  //   background: red;
  //   animation: zoomRotate 4s infinite alternate;

  // }

  img {
    width: 100%;
    object-fit: fill;
    border-radius: 50%;
  }

  .name {
    text-align: center;
    background: $grayBg;
    padding: 30px;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    color: $sec-txt;
    text-transform: capitalize;
    font-weight: 500;
  }
}

@keyframes zoomRotate {
  0% {
    transform: scale(1) rotate(0deg);
  }

  100% {
    transform: scale(1.5) rotate(360deg);
  }
}

.profile-banner {
  .breathing-circle {
    overflow: unset;
  }

  .edit-icon {
    cursor: pointer;
    display: block;
    width: 40px;
    background: #00000078;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    right: 28px;
    top: 80%;
    overflow: hidden;
    padding: 11px;

    img {
      border-radius: 0;
      position: absolute;
      width: 21px;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@keyframes breathe {
  0% {
    box-shadow: 0 0 0 30px rgba(188, 237, 243, 0.3);
  }
}

// View Advisor Style
.view-advisor {
  display: flex;
  align-items: center;

  .rating-grp {
    display: flex;
    align-items: center;
    grid-gap: 18px;
    padding-top: 18px;

    .star-ratings {
      background: $clr-white;
      border-radius: 30px;
      width: 110px !important;
      height: 26px;
      padding: 4px 10px;
    }
  }

  .view-description {
    padding-top: 15px;

    .description {
      max-width: 60%;
    }
  }

}

.profile-banner {
  .breathing-circle {
    left: -38%;
  }
}

@media screen and (max-width: 1600px) {
  .breathing-circle {
    left: -17%;
  }
}

@media screen and (max-width: 1366px) {
  .breathing-circle {
    left: -25%;
  }

  .text-wrapper {
    width: 85%;
  }
}

@media screen and (max-width: 992px) {
  .common-banner {
    .container {
      max-width: 85%;
    }

    .banner-bg {
      .text-container {
        .text-wrapper {
          width: 100%;
        }
      }
    }
  }

  .breathing-circle {
    left: -5%;
  }
}

@media screen and (max-width: 768px) {
  .common-banner {
    .banner-bg {
      height: auto;

      .text-container {
        flex-direction: column;

        .text-wrapper {
          width: 100%;
          padding: 30px 0;
        }
      }
    }

    .breathing-circle {
      left: 0;
    }
  }

  .view-advisor {
    flex-direction: column;

    .view-description {
      border-left: 0;
      padding: 10px 0 10px 0px;
    }
  }
}

@media screen and (max-width: 575px) {
  .text-wrapper {

    h1 {
      font-size: 40px !important;
    }
  }

  .common-banner {
    .container {
      max-width: 95%;
    }
  }

  .view-advisor {
    .view-description {
      .description {
        max-width: 100%;
      }
    }
  }
}