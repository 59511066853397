.contact {
    .contact-info {
        padding-right: 0;

        .content-left {
            height: 100%;

            .address {
                background-image: $banner-gradient;
                color: $clr-white;
                border-radius: 20px;
                height: 100%;
                padding: 48px;
                position: relative;

                h1 {
                    font-size: $fs-h3;
                    font-weight: $semibold;
                    margin-bottom: 29px;
                }

                .details {
                    display: flex;

                    .address-logo {
                        margin-right: 20px;
                    }

                    p {
                        a {
                            text-decoration: none;
                            color: unset;
                        }

                        font-size: $fs-base;
                        font-weight: $medium;
                        margin-bottom: 29px;
                    }
                }

                .socialmedia {
                    position: absolute;
                    bottom: 48px;

                    p {
                        font-size: $fs-base;
                        font-weight: $medium;
                    }

                    .social-logo {
                        padding-top: 10px;

                        a {
                            margin-right: 13px;
                        }
                    }
                }
            }

        }
    }

    .contact-form {
        padding-left: 0;

        .content-right {
            margin-left: 25px;
            padding-left: 25px;

            h2 {
                span {
                    @include text-Gradient(55deg, 26.23%, 61.02%);
                    font-weight: $bold;
                }
            }

            p {
                font-weight: $medium;
                margin: 13px 0 34px;
            }

            .form-group {
                position: relative;
                margin-bottom: 12px;
                padding-bottom: 12px;

                input {
                    height: 55px;
                    border-radius: 13px;
                    border: 1px solid $input-border;
                    background-color: $body-color;
                    color: $input-text;
                    font-weight: $medium;
                    padding: 17px 21px;
                    transition: all 0.3s ease;
                    &.is-invalid {
                        border-color: $error;
                    }
                }

                .placeholder {
                    position: absolute;
                    cursor: text;
                    top: 20px;
                    left: 21px;
                    background-color: unset;
                    color: $input-label;
                    opacity: unset;
                    transition: all 0.3s ease;
                }

                :focus {
                    border-color: $input-border;
                    box-shadow: unset;

                    +.placeholder {
                        top: -8px !important;
                        background-color: $body-color;
                        color: $input-focus;
                        opacity: unset;
                        padding: 0 5px;
                    }
                }

                input {
                    &.valid-field {
                        +.placeholder {
                            top: -8px;
                            background-color: $body-color;
                            color: $input-focus;
                            opacity: unset;
                            padding: 0 5px;
                        }
                    }
                }

                textarea {
                    height: 124px;
                    border-radius: 13px;
                    border: 1px solid $input-border;
                    background-color: $body-color;
                    color: $input-text;
                    font-weight: $medium;
                    padding: 15px;
                    resize: none;
                    overflow: auto;

                    &.is-invalid {
                        border-color: $error;
                    }

                    &.valid-field {
                        +.placeholder {
                            top: -8px;
                            background-color: $body-color;
                            color: $input-focus;
                            opacity: unset;
                            padding: 0 5px;
                        }
                    }
                }
            }

            .btn1 {
                margin-top: 6px;
                padding-top: 6px;
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .contact {
        .contact-info {
            padding-right: 12px;

            .content-left {
                .address {
                    .socialmedia {
                        position: unset;
                    }
                }
            }
        }

        .contact-form {
            padding-left: 12px;

            .content-right {
                margin-left: 0;
                margin-top: 25px;
                padding-left: 0;
                padding-top: 25px;
            }
        }
    }
}

@media only screen and (max-width:430px) {
    .contact {
        .contact-info {
            .content-left {
                .address {
                    padding: 25px;
                }
            }
        }
    }
}