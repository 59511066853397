// accordion
.accordion-menu {
  width: 100%;
  padding-right: 25px;

  .accordion-item {
    position: relative;
    transition: all 0.3s ease-in-out;
    @include border-radius(15px);
    overflow: hidden;
    margin-bottom: 10px;
    cursor: pointer;

    &.open-item {
      transition: all 0.3s ease-in-out;

      .accordion-header {
        img {
          transform: rotate(180deg);
        }
      }
    }

    .accordion-header {
      background: $clr-white;
      padding: 18px 27px;
      @include flex-between;

      h5 {
        @include textGradient;
        font-weight: $semibold;
        font-size: $h5-big;
        text-transform: capitalize;
      }

      .action {
        @include flex-center;

        .count {
          font-weight: $semibold;
          font-size: $fs-mini;
          margin-right: 10px;
          width: 23px;
          height: 23px;
          padding: 0;
          @include justify-center;
        }
      }
    }

    .accordion-body {
      padding: 18px 27px;
      background: $lightGrayBg;
      border-top: 1px solid $body-color;
      font-size: 18px;
      font-weight: $medium;
      @include flex-between;

      .active-border {
        left: 0;
        width: 20px;
        height: 100%;
        background: transparent;
        top: 0;
        border-radius: 30px;
      }

      .content-body {
        text-transform: capitalize;
        position: relative;
        transition: all 0.8s ease-in-out;
      }
    }
  }
}